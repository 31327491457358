import type { RaRecord } from "react-admin";

export interface Device extends RaRecord {
  id: string;
  IMEI: string;
  name: string;
  bypassedRemoteSwitch: boolean;
  VersionHardware?: string;
  majorHWVersion: 1 | 2 | 3;
  Telemetry: number;
  heater?: string;
  custom_pilot: boolean;
  HoraireHCHP: any[];
  contractType: ContractType;
  status: DeviceStatus;
  firmwareVersion: string | undefined;
  targetFirmwareVersion: string | undefined;
  referenceEnergy: number | null;
  energy_ontimer_last_seven_days: null | number[];
  isCaServerUpToDate?: boolean | null;
  targetCertificate: {
    crc: number;
    generationDate: Date;
    expirationDate: Date;
  };
  currentCertificate: {
    crc: number;
    expirationDate: Date;
  };
  residentAppUrl: string;
}

export enum DeviceStatus {
  PRODUCTION = "Production",
  STOCK = "Stock",
  USED = "Used",
  TEST = "Test",
  OUT_OF_ORDER = "Out of order",
}

export enum ContractType {
  BASE = "BASE",
  HP_HC = "HP/HC",
  UNKNOWN = "UNKNOWN",
}

export enum SeriesName {
  T1 = "T1°",
  T2 = "T2°",
  T1_AVG = "T1°(av)",
  T2_AVG = "T2°(av)",
  T_DIFF = "ΔT°(av)",
  SCHEDULE = "Config Target",
  SCHEDULE_2 = "Config Target (relay 2)",
  CONFIG_ACK = "Config Received",
  CONFIG_ACK_2 = "Config Received (relay 2)",
  CONFIG = "Config Sent",
  CONFIG_2 = "Config Sent (relay 2)",
  ENERGY_CUMULATIVE = "Energy",
  ENERGY = "Energy (columns)",
  ON_TIMER = "On Timer",
  HISTORY = "History",
}

export interface DailyStats extends RaRecord {
  day: string;
  energy: number;
  energy_raw: number;
  data_coverage: number;
  inconsistent_data_coverage: number;
}

export interface DailySavingsStats extends RaRecord {
  day: string;
}
