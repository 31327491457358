import { Alert, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import type { Device } from "../../../types";
import { DailySavings } from "./DailySavings";
import { Energies } from "./Energies";
import { SavingsTable } from "./Savings";

export const EnergyTab = () => {
  const record = useRecordContext<Device>();
  return (
    <>
      {record && (
        <Alert severity="info" sx={{ margin: 1 }}>
          Retrouvez le nouveau dashboard associé à ce device sur metabase:{" "}
          <a
            href={`https://metabase.elaxenergy.com/dashboard/52-dashboard-principal-sur-le-parc?device_id=${encodeURIComponent(
              record.id
            )}&tab=58-donn%C3%A9es-d%27%C3%A9nergie-par-bo%C3%AEtier`}
          >
            Voir le dashboard metabase
          </a>
        </Alert>
      )}

      <SavingsTable />
      <Typography variant="h5" marginTop={4}>
        Daily energies
      </Typography>
      <Energies />
      <Typography variant="h5" marginTop={4}>
        Daily savings
      </Typography>
      <DailySavings />
    </>
  );
};
